import "./Hero.css";

import { AnimatePresence, AnimateSharedLayout, motion } from "framer-motion";
import { useState } from "react";
import FooterCard from "../FooterCard/FooterCard";
import ContactCard from "../ContactCard/ContactCard";

export default function Hero() {
  const [contactOpen, setContactOpen] = useState(false);

  return (
    <div className="hero-container">
      <div className="hero">
        <div className="hero-gradient">
          <h1>
            food,
            <br />
            freedom
          </h1>
          <motion.button onClick={() => setContactOpen(true)}>
            Contact Us
          </motion.button>
          {contactOpen &&
            <ContactCard setActive={setContactOpen} />
          }
        </div>
      </div >
    </div >
  );
}
