import { AnimatePresence, AnimateSharedLayout, motion } from "framer-motion";
import { Ref, useEffect, useRef, useState } from "react";
import "./AdvHeader.css";

interface AdvHeaderProps {
  title: string;
}

export default function AdvHeader(props: AdvHeaderProps) {
  const [mainInView, setMainInView] = useState(true);
  const bigTitleRef = useRef(null);

  useEffect(() => {
    if (('IntersectionObserver' in window)) {
      const options = {
        threshold: [0.25],
        rootMargin: "0px",
      };
      const callback: IntersectionObserverCallback = (entries, observer) => {
        setMainInView(entries[0].isIntersecting);
      };
      const observer = new IntersectionObserver(callback, options);
      observer.observe(document.getElementsByClassName("adv-header-big")[0])

    };
  }, []);

  return (
    <>
      <motion.h1
        layout
        ref={bigTitleRef}
        layoutId="layout-adv-header"
        className="adv-header-big"
        animate={{ opacity: mainInView ? 1 : 0 }}
      >
        {props.title}
      </motion.h1>

      <AnimatePresence>
        {!mainInView && (
          <div className="adv-header-container">
            <motion.div
              layoutId="layout-adv-header"
              className="adv-header-pill"
              initial={{ opacity: 0, y: -75, boxShadow: "none" }}
              animate={{
                opacity: 1,
                y: 0,
                boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.3)",
              }}
              exit={{ opacity: 0, y: -75, boxShadow: "none" }}
            >
              <motion.p>{props.title}</motion.p>
            </motion.div>
          </div>
        )}
      </AnimatePresence>
    </>
  );
}
