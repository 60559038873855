import { AnimatePresence, motion, Variants } from 'framer-motion';
import { ContactCardProps } from '../Interfaces';
import './ContactCard.css';
import Scrollbars from 'react-custom-scrollbars-2';

export default function ContactCard(props: ContactCardProps) {

	const email = "Z2VnZkBwbS5tZQ=="
	const mobile = "MDI3IDI5MSA4MDkx"

	return (
		<AnimatePresence>
			<motion.div
				key="anim-contact-card"
				className="contact-card-container"
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				exit={{ opacity: 0 }}
				onClick={() => props.setActive(false)}
			>
				<motion.div
					className="contact-card"
					initial={{ opacity: 0.75, scale: 0.75 }}
					animate={{ opacity: 1, scale: 1 }}
					exit={{ opacity: 0.75, scale: 0.75 }}
					onClick={(e) => e.stopPropagation()}
				>
					<motion.h2>Contact Us</motion.h2>
					<motion.div className="contact-people">

						<motion.div className="contact-person">
							<motion.img src="/images/contact/jo.jpg" />
							{/* <motion.h2>Jim O'Gorman</motion.h2> */}
						</motion.div>


						<motion.div className="contact-person">
							<motion.img src="/images/contact/mj.png" />
							{/* <motion.h2>Marion Johnson</motion.h2> */}
						</motion.div>
					</motion.div>

					<motion.div className="contact-entry"
						onClick={() => (
							window.location.href = `mailto:${atob(email)}`
						)}
					>
						<img src="/icons/email.svg" />
						<p>{atob(email)}</p>
					</motion.div>

					<motion.div className="contact-entry"
						onClick={() => {
							window.location.href = `tel:+64${atob(mobile)}`
						}}
					>
						<img src="/icons/phone.svg" />
						<p>{atob(mobile)}</p>
					</motion.div>

				</motion.div>

			</motion.div>
		</AnimatePresence >
	)
}