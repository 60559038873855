import { useState } from 'react';
import ContactCard from '../ContactCard/ContactCard';
import './FooterCard.css';

export default function FooterCard() {

	const [contactOpen, setContactOpen] = useState(false);

	return (
		<>
			<div className="footer-card">
				<img src="./images/gegf-logo-footer.png" alt="Company logo (large)" />
				<div>
					<div className="links">
						<a href="https://www.youtube.com/channel/UCkg0XyrAa1JmRZdHsjd2xLA">
							<img alt="youtube logo" src="./icons/youtube.svg" width="32" height="32" /></a>
						<a href="https://twitter.com/gegfofficial">
							<img alt="twitter logo" src="./icons/twitter.svg" width="32" height="32" />
						</a>
					</div>
					<div className="primary-contact">
						<button className="contact-button" onClick={() => setContactOpen(true)}> Contact Us </button>
					</div>
				</div>
			</div>

			{contactOpen &&
				<ContactCard setActive={setContactOpen} />
			}
		</>
	)
}