import "./App.css";
import Navbar from "./Components/Navbar/Navbar";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import { useEffect, lazy } from "react";
import ReactGA from 'react-ga';
import VideosView from "./Components/VideosView/VideosView";
import AdvHeader from "./Components/AdvHeader/AdvHeader";
import Scrollbars from "react-custom-scrollbars-2";
import { displayPartsToString } from "typescript";
import Loader from "react-loader-spinner";
import HomeView from "./Components/HomeView/HomeView";


function ResearchView() {

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, [])

  return (
    <Scrollbars
      id="scrollbars_video"
      style={{ width: "100vw", height: "100vh", zIndex: 5 }}
    >
      <div className="safe-area-content">

        <AdvHeader title="RESEARCH" />
        <div>
          <p style={{ width: '80%', textAlign: "center", marginLeft: "auto", marginRight: "auto", fontWeight: 800, marginBottom: 120 }}>
            Farm focussed scientists working with science minded farmers, comfortably combined with local and traditional knowledge- the best of all worlds.
          </p>

          <p style={{ width: '80%', textAlign: "justify", marginLeft: "auto", marginRight: "auto" }}>

            Jim’s soils work focuses on restoring and regenerating the soils at his home, 'A place to be.' His mantra is “Balancing the chemistry by managing the biology.” <br /> <br />
            Marion's passion is botanical medicine, and it's contribution, not only to animal health, but to farm health and biodiversity. She enjoys working with farmers on alternatives to industrial agriculture.
            {/* Marion’s current research collaborations and projects include: <br />
            <ul>
              <li>Pathways to phase out contentious inputs from organic agriculture in Europe- focussing on Dairy, Healthy pastures and herbs as immunomodulators to improve livestock health</li>
              <li>Global initiative for Traditional Solutions, Antimicrobial resistance</li>
              <li>Food baskets for animal and human health.</li>
            </ul> */}

          </p>

          <p style={{ width: '80%', textAlign: "center", marginLeft: "auto", marginRight: "auto", fontWeight: 800, fontSize: "1rem", marginTop: 120 }}>
            Page under development. More information coming soon.
          </p>

        </div>
      </div>
    </Scrollbars>
  );
}

function BlogView() {

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, [])

  return (
    <div className="safe-area-content">
      <AdvHeader title="BLOG" />
      <div>
        <p style={{ textAlign: "center" }}> This section is under construction. </p>
      </div>
    </div>
  );
}

function subPageLoader() {
  return (
    <div style={{
      height: '100vh',
      width: '100vw',
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    }}>
      <Loader
        type="TailSpin"
        color="#000000"
        width={80}
        height={80} />
    </div>
  )
}

function App() {
  return (
    <BrowserRouter>
      <Navbar />
      <div id="content-container">
        <Switch>
          <Route path="/" component={HomeView} exact />
          <Route path="/research" component={ResearchView} />
          <Route path="/videos" component={VideosView} />
          <Route path="/blog" component={BlogView} />
        </Switch>
      </div>
    </BrowserRouter>
  );
}

document.title = "Good Earth Great Food"

export default App;