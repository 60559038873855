import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Helmet } from "react-helmet";
import smoothscroll from "smoothscroll-polyfill";
import ReactGA from 'react-ga';

ReactGA.initialize('UA-189151361-2')

ReactDOM.render(
  <React.StrictMode>
    <Helmet>
      <title>Good Earth Great Food</title>
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-status-bar-style" content="default" />
      <meta name="google-site-verification" content="XrOZFfEgnQg1qM0q2tvII2grOgYq32sRvx5z6Y_AOmY" />
      {/* icons are used in the nav menu */}
      <link rel="preload" href="/icons/home.svg" as="image" />
      <link rel="preload" href=" /icons/videos.svg" as="image" />
      <link rel="preload" href="/icons/research.svg" as="image" />
      <link rel="preload" href="/icons/blog.svg" as="image" />
      <link rel="preload" href="/icons/close.svg" as="image" />
      {/* icons used in contact modal */}
      <link rel="preload" href="/icons/phone.svg" as="image" />
      <link rel="preload" href="/icons/email.svg" as="image" />
    </Helmet>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

smoothscroll.polyfill();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
