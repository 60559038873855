import "./Navbar.css";
import { AnimatePresence, AnimateSharedLayout, motion } from "framer-motion";
import { useEffect, useState, useRef } from "react";
import _ from "lodash";
import { NavLink } from "react-router-dom";
import IconLink from "./IconLink";
import { useHistory } from "react-router";

export default function Navbar() {

  const history = useHistory();

  const [menuOpen, setMenuOpen] = useState(false);

  function closeMenu() {
    setMenuOpen(false);
  }

  return (
    <>
      <div className="navbar-grad" />
      <button
        onClick={() => history.push("/")}
        className="nav-button logo-pill"
      >
        <img src="./images/gegf-logo-mono.png" alt="Company logo" />
      </button>
      <AnimateSharedLayout type="crossfade">
        <motion.button
          layoutId="layout-nav-menu"
          className="nav-button menu-button"
          onClick={() => setMenuOpen(true)}
          aria-label="Navigation Menu"
        >
          <img src="./icons/hamburger.svg" alt="" width="24" height="24" />
        </motion.button>

        <AnimatePresence>
          {menuOpen && (
            <motion.div
              className="menu-container"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              onClick={() => setMenuOpen(false)}
            >
              <motion.div
                className="menu"
                layoutId="layout-nav-menu"
                onClick={(e) => e.stopPropagation()}
                initial={{ opacity: 1 }}
              >
                <IconLink
                  closeMenu={closeMenu}
                  to="/"
                  title="Home"
                  icon="./icons/home.svg"
                />
                <IconLink
                  closeMenu={closeMenu}
                  to="/videos"
                  title="Videos"
                  icon="./icons/videos.svg"
                />
                {/* <IconLink
                  closeMenu={closeMenu}
                  to="/blog"
                  title="Blog"
                  icon="./icons/blog.svg"
                /> */}
                <IconLink
                  closeMenu={closeMenu}
                  to="/research"
                  title="Research"
                  icon="./icons/research.svg"
                />
                <a className="icon-link" onClick={() => setMenuOpen(false)}>
                  <div className="icon-link-icon-container">
                    <img src="./icons/close.svg" />
                  </div>
                  <p>Close</p>
                </a>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
      </AnimateSharedLayout>
    </>
  );
}
