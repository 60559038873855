import "./VideosView.css";
// import Tilt from "react-parallax-tilt";
import { AnimatePresence, AnimateSharedLayout, motion } from "framer-motion";
import { useState, useEffect } from "react";
import FooterCard from "../FooterCard/FooterCard";
import { browserName } from "react-device-detect";
import AdvHeader from "../AdvHeader/AdvHeader";
import Scrollbars from "react-custom-scrollbars-2";
import ReactGA from 'react-ga';

interface SampleVideoEntry {
  id: number;
  title: string;
  date: string;
  url: string;
}

const entries: SampleVideoEntry[] = [
  {
    id: 1,
    title: "Bioremediation",
    date: "26/08/2021",
    url: "TSMrXPddSBk"
  },
  {
    id: 2,
    title: "What is compost tea?",
    date: "26/08/2021",
    url: "vPdI7Ncon6w"
  },
  {
    id: 3,
    title: "Jim's Books",
    date: "26/08/2021",
    url: "U6J-cQ-2BTI",
  },
  {
    id: 4,
    title: "Managing Your Weeds with Dirt Doctor NZ",
    date: "24/08/2021",
    url: "hZhytnvz0bk"
  },
  {
    id: 5,
    title: "Soil Science",
    date: "30/07/2021",
    url: "q6weYUDdqfc",
  },
  {
    id: 6,
    title: "Planting Seeds In Trays",
    date: "06/08/2021",
    url: "l2JVSs0Otr0",
  },
  {
    id: 7,
    title: "Intoducing Jim O'Gorman",
    date: "27/07/2021",
    url: "bpYYoqGXDnQ",
  },
  {
    id: 8,
    title: "Preparing beds for planting",
    date: "07/08/2021",
    url: "B-HixklI23c",
  },
  {
    id: 9,
    title: "Composting Part One",
    date: "27/07/2021",
    url: "fKaSkxZJIy0",
  },
  {
    id: 10,
    title: "Composting Part Two",
    date: "27/07/2021",
    url: "RyxWADFk8ko",
  },
  {
    id: 11,
    title: "Composting Part Three",
    date: "11/07/2021",
    url: "e405IniX9VA",
  },
];

// mockup for now... no real data
export default function VideosView() {
  const [selectedVideo, setSelectedVideo] = useState<null | SampleVideoEntry>(
    null
  );

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, [])

  return (
    <Scrollbars
      id="scrollbars_video"
      style={{ width: "100vw", height: "100vh", zIndex: 5 }}
    >
      <div className="safe-area-content">
        <AdvHeader title="VIDEOS" />
        <div className="videos-list-container">
          {entries.map((entry) => (
            <motion.div
              className="video-card"
            >
              <motion.img
                className="video-card-image"
                src={`https://img.youtube.com/vi/${entry.url}/0.jpg`}
                onClick={() => {
                  setSelectedVideo(entry);
                }}
              />
              <motion.div className="video-info">
                <motion.p>{entry.title}</motion.p>
                <motion.p>{entry.date}</motion.p>
              </motion.div>
            </motion.div>
          ))}

          <AnimatePresence>
            {selectedVideo && (
              <>
                <motion.div
                  className="open-video-container"
                  onClick={() => setSelectedVideo(null)}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  <motion.div className="open-video">
                    <iframe
                      className="open-video-video"
                      src={`https://www.youtube.com/embed/${selectedVideo.url}?autoplay=1&mute=1`}
                    ></iframe>
                  </motion.div>
                  <motion.button className="video-extern-button" onClick={() =>
                    window.open(
                      `https://www.youtube.com/watch?v=${selectedVideo.url}`,
                      '_blank' // <- This is what makes it open in a new window.
                    )}>
                    <img src="/icons/extern.svg" />
                    Watch on YouTube
                  </motion.button>
                </motion.div>

              </>
            )}
          </AnimatePresence>
        </div>
        <FooterCard />
      </div>
    </Scrollbars >
  );
}
