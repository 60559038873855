import FooterCard from "../FooterCard/FooterCard";
import Hero from "../Hero/Hero";
import Scrollbars from "react-custom-scrollbars-2";
import ReactGA from 'react-ga';
import { lazy, Suspense, useEffect } from "react";
import Loader from "react-loader-spinner";
const ImageGallery = lazy(() => import('../ImageGallery/ImageGallery'));

function GalleryLoaderStandin() {
  return (
    <div style={{
      width: '100vh',
      height: "380px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    }}>
      <Loader
        type="TailSpin"
        color="#2d401c"
        width={80}
        height={80} />
    </div>
  )
}

export default function HomeView() {

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, [])

  return (
    <>
      <Scrollbars style={{ width: "100vw", height: "100vh", zIndex: 5 }}>
        <Hero />
        <p style={{ fontStyle: "italic", textAlign: 'center' }}> Note: this website is under active development.</p>
        <div className="paragraphs" style={{ marginBottom: 100 }}>
          <div className="paragraph">
            <h1 className="header"> What? </h1>
            <p>
              Good Earth Great Food is about developing, researching and communicating vital skills. We embrace the ideas of Agroecology but feel that people get constrained by dogmas and labels, so encourage simply good ethical management as a first step. We welcome you to join us.
              <ul>
                <li>Reconnection – learning where our food comes from, how to produce it in a respectful manner. Growing, eating and farming with the rhythms of the season and the earth. Walking with nature wherever you may find it.</li>
                <li>Restoration – working to re-establish functioning ecospheres, waterways, farmscapes, landscapes, communities...</li>
                <li>Regeneration - rebuilding our soils, pastures, orchards, vineyards, waterways so that they are healthy and functional ecosystems driven by their own internal processes not industrial inputs. Restoring life and vitality.</li>
                <li>Resilience – ensuring that we, our land, livestock, crops can bounce back. Healthy, vital systems that continue to survive and feed us regardless of what may occur in the future.</li>
              </ul>
            </p>
          </div>



          <div className="paragraph">
            <h1 className="header">Who?</h1>
            <p>
              Jim, at his property, "A Place to Be", is well known as “The Dirt Doctor.” He has taught his horticultural techniques to many eager students and his produce is sought after. He features in the Kathleen Gallagher documentary “Earth whispers Papatūānuku” and is the subject of Rachel Patching’s documentary “the Dirt Doctor” screened on BBC Knowledge in 2014. Marion spent her early years shepherding and managing farms in NZ, Zambia and the UK and then went back to University. She has degrees in Agriculture and Environmental Biology and a PhD in Veterinary Parasitology. Her current research encompasses Agroecology, Ethnoveterinary practices, Botanical medicine, healthy pastures and questions around Sustainability. In addition to being an independent researcher in NZ she is affiliated to the Animal Husbandry Lab at the Aristotle University Thessaloniki Greece. She lectures, publishes and organises conferences and field days. Good Earth Great Food organized a series of successful farmer to farmer days, which we look forward to repeating in the near future.
            </p>
          </div>

        </div>

        <Suspense fallback={GalleryLoaderStandin}>
          <ImageGallery />
        </Suspense>

        <div className="paragraphs" style={{ marginBottom: 100 }}>

          <div className="paragraph">
            <h1 className="header">Why?</h1>
            <p>
              We are at a cross roads and we need to take a careful turn, the viability of our planet depends upon it. Industrially based agriculture and food production has had unintended consequences, it has delivered unhealthy soils, sick animals, antibiotic resistance, undrinkable, unswimmable water and crops that are loaded with chemical residues. Many people are malnourished or hungry and the climate is changing. We must change with it.
            </p>
          </div>


          <div className="paragraph">
            <h1 className="header">How?</h1>
            <p>
              We can transform our food production, but we must also reshape our social constructs. We can feed ourselves but we need to alter the way we do it and how we eat. We must grow nutrient dense foodstuffs, limit our animal protein consumption to healthy pastured species and celebrate health – people, plants and planet not accoutrements and epitaphs to ego. So many of the skills of earlier generations have been lost but working together, embracing diversity of knowledge and worldview we can forge a sage, empathic, regenerative, resilient, sustainable future for all.
            </p>
          </div>
        </div>
        <FooterCard />
      </Scrollbars>
    </>
  );
}
